import { useStore } from "@/store/store";
import {
  computed,
  defineComponent,
  onBeforeMount,
  reactive,
  ref,
  watch,
} from "vue";
import { useRouter } from "vue-router";

import isEqual from "lodash-es/isEqual";

import { SortingDirection } from "../../interfaces/SortingItem";

import useUserRoles, { UserRole } from "@/uses/useUserRoles";

import { RangeFilterItem } from "@/components/RangeFilter/RangeFilter";
import { FilterObject } from "@/components/DropdownFilter/DropdownFilter";
import { SortingItem } from "@/interfaces/SortingItem";

import TableHeaderComponent from "@/components/TableHeaderComponent/TableHeaderComponent.vue";
import SearchInput from "@/components/SearchInput/SearchInput.vue";

import CancelIcon from "@/assets/icons/Cancel.vue";
import PlayCircleIcon from "@/assets/icons/PlayCircle.vue";
import InfoIcon from "@/assets/icons/Info.vue";

import { getSitesForFilter } from "@/api/SitesApi";
import { getSitesFilterItem } from "@/utils/filtersUtils";
import { showError, showErrorOptional } from "@/utils/notificationUtils";
import {
  AssessmentType,
  canStartAssessment,
  CodeStatus,
  SubjectItem,
} from "@/api/interfaces/SubjectsInterfaces";
import { Sex } from "@/api/SitePresetsApi";
import {
  getSubjectsSearch,
  revokeAssesment,
  startNewAssesment,
} from "@/api/SubjectsApi";
import useSexFormatter from "@/uses/useSexFormatter";
import format from "date-fns/format";
import useDateFormatter from "@/uses/useDateFormatter";
import isArray from "lodash-es/isArray";

export default defineComponent({
  name: "SubjectsList",
  props: {},
  components: {
    SearchInput,
    TableHeaderComponent,
    CancelIcon,
    PlayCircleIcon,
    InfoIcon,
  },
  setup() {
    const router = useRouter();
    const store = useStore();

    const fieldNames = {
      id: "id",
      subject_id: "subject_id",
      site_name: "site_id",
      sex_type: "sex_type",
      year_of_birth: "year_of_birth",
      subject_assessment_info_list: "subject_assessment_info_list",

      assessment: {
        assessment_type: "assessment_type",
        assessments_amount: "assessments_amount",
        invitation_code_status: "invitation_code_status",
        invitation_code_value: "invitation_code_value",
        last_assessment_id: "last_assessment_id",
        last_assessment_timestamp: "assessment_timestamp",
      },
    };

    const list = ref<{ data: SubjectItem[]; selected: SubjectItem[] }>({
      data: [],
      selected: [],
    });

    const { startYear, endYear } = useDateFormatter();

    const state = reactive({
      search: "",
      filtersData: {
        sex_type: {
          items: [
            {
              options: [
                {
                  value: Sex.Male,
                  text: "Male",
                },
                {
                  value: Sex.Female,
                  text: "Female",
                },
              ],
            },
          ],
          values: [],
        } as FilterObject,
        sites: {
          items: [],
          values: [],
        } as FilterObject,
        year_of_birth: {
          from: "",
          to: "",
          min: startYear,
          max: endYear,
          isYear: true,
        } as RangeFilterItem,
      },
      paging: {
        page: 1,
        pageSize: 30,
        total: 0,
      },
      sorting: null as SortingItem[] | null,
      isLoading: false,
    });

    const updateSubjects = async (clearPaging = false) => {
      if (clearPaging) {
        state.paging.page = 1;
      }

      state.isLoading = true;
      try {
        const filter = {} as any;
        if (state.filtersData.year_of_birth.from)
          filter.year_of_birth_from = state.filtersData.year_of_birth.from;
        if (state.filtersData.year_of_birth.to)
          filter.year_of_birth_to = state.filtersData.year_of_birth.to;

        const subjects = await getSubjectsSearch({
          paging: {
            page_number: state.paging.page,
            amount_per_page: state.paging.pageSize,
          },
          search_pattern: state.search,
          filters: [
            {
              field: fieldNames.sex_type,
              values: state.filtersData.sex_type.values,
            },
            {
              field: fieldNames.site_name,
              values: state.filtersData.sites.values,
            },
          ].filter((f) => (isArray(f.values) ? f.values.length : f.values)),
          ...filter,
          sorting: state.sorting ? state.sorting : undefined,
        });
        state.paging.total = subjects.total;
        list.value.data = subjects.entities;
      } catch (ex) {
        showErrorOptional("Error while updating subjects", ex);
        console.log(ex);
      } finally {
        state.isLoading = false;
      }
    };

    watch(
      () => ({
        sex_type: state.filtersData.sex_type.values,
        sites: state.filtersData.sites.values,
      }),
      (newValue: any, oldValue: any) => {
        console.log("filter updated");
        if (!isEqual(newValue, oldValue)) {
          updateSubjects(true);
        }
      }
    );

    watch(
      () => ({
        from: state.filtersData.year_of_birth.from,
        to: state.filtersData.year_of_birth.to,
      }),
      (newValue: any, oldValue: any) => {
        console.log("range filter updated");
        if (!isEqual(newValue, oldValue)) {
          console.log(state.filtersData.year_of_birth);
          updateSubjects(true);
        }
      }
    );

    const useRoles = useUserRoles(store);

    onBeforeMount(() => {
      if (useRoles.hasRole(UserRole.SystemAdmin, UserRole.Analyst)) {
        getSitesForFilter().then((sites) => {
          state.filtersData.sites.items = getSitesFilterItem(sites);
        });
      }

      updateSubjects();
    });

    const getLastAssessment = (item: SubjectItem) =>
      item.subject_assessment_info_list.find(
        (a) => a.assessment_type === AssessmentType.HiPAL
      ) || null;

    return {
      state,
      list,
      fieldNames,
      ...useRoles,
      ...useSexFormatter(),
      ...useDateFormatter(),
      getSortingValueForField: computed(() => (field: string) => {
        if (state.sorting) {
          const sorting = state.sorting[0];
          return sorting.field === field ? sorting.direction : null;
        }
        return null;
      }),
      addSubject: () => {
        router.push(`/subjects/add-subject`);
      },
      editSubject: (subject: SubjectItem) => {
        router.push(
          `/subjects/edit-subject/${
            subject.site_id ? `${subject.site_id}/` : ""
          }${subject.id}`
        );
      },
      deleteSubject: (subject: SubjectItem) => {
        // TODO Add after delete
      },
      viewSubject: (subject: SubjectItem) => {
        router.push(
          `/subjects/view-subject/${
            subject.site_id ? `${subject.site_id}/` : ""
          }${subject.id}`
        );
      },
      pageChange: (page: number) => {
        state.paging.page = page;
        updateSubjects();
      },
      searchChanged() {
        updateSubjects(true);
      },
      sortingChange(field: string, direction: SortingDirection | null) {
        console.log("sortingChange", field);
        if (direction === null) {
          state.sorting = null;
        } else {
          state.sorting = [{ field, direction }];
        }
        updateSubjects(true);
      },
      selectionChange(val: SubjectItem[]) {
        list.value.selected = val;
      },
      getLastAssessment,
      lastAssessments: computed(() => {
        return list.value.data.map(
          (d) =>
            d.subject_assessment_info_list.find(
              (a) => a.assessment_type === AssessmentType.HiPAL
            ) || null
        );
      }),
      revokeDisabled: computed(
        () =>
          !(
            list.value.selected.length &&
            list.value.selected.every(
              (s) =>
                !canStartAssessment(
                  getLastAssessment(s)?.invitation_code_status
                )
            )
          )
      ),
      startNewAssessmentDisabled: computed(
        () =>
          !(
            list.value.selected.length &&
            list.value.selected.every((s) =>
              canStartAssessment(getLastAssessment(s)?.invitation_code_status)
            )
          )
      ),
      CodeStatus,
      async startNewAssessment(subject?: SubjectItem) {
        const listOfSubjects = subject ? [subject] : list.value.selected;
        listOfSubjects.forEach(async (s) => {
          const result = await startNewAssesment(s?.id as string, s?.site_id);

          const lastAssessment = s.subject_assessment_info_list[0];
          lastAssessment.invitation_code_status = result.status;
          lastAssessment.invitation_code_value = result.code;
        });
      },
      revoke(subject?: SubjectItem) {
        const listOfSubjects = subject ? [subject] : list.value.selected;
        listOfSubjects.forEach(async (s) => {
          try {
            await revokeAssesment(s?.id as string, s?.site_id);
            s.subject_assessment_info_list[0].invitation_code_status =
              CodeStatus.Revoked;
          } catch (ex) {
            console.log(ex);
          }
        });
      },
      goToLastAssessment(subject: SubjectItem) {
        router.push({
          name: "View HiPAL Pro",
          params: {
            id: subject.id || "",
            siteId: subject.site_id || "",
            assessmentId: getLastAssessment(subject)?.last_assessment_id || "",
          },
        });
      },
    };
  },
});
